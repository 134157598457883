import { Link } from "gatsby";
import { Card, Table, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import * as React from 'react';
export default {
  Link,
  Card,
  Table,
  Image,
  FontAwesomeIcon,
  faThumbsUp,
  faThumbsDown,
  React
};